import React, { useEffect, useState } from "react"
import { Card, Typography, Button, Row, Col, Space, Tag, notification } from 'antd';
import { get } from 'lodash'
import { t } from '../../helpers/translation'
import {convertMinsToHour} from "../../utils/number";
import { discountValueFormatter, getCoupon, getMenus, calculateDiscount } from '../../helpers/bookings'
import { addCoupon, getStore, clearBooking, getStylist, setAddCouponMenuFlag } from '../../helpers/bookings'
import { sumBy } from 'lodash'
import './styles.scss'

const { Paragraph, Title } = Typography;
const MENU_LIMIT_DISPLAY = 3;

export const CouponCard = ({ coupon, storeId, showButtons = true }) => {
  const [ellipsis, setEllipsis] = useState(true);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [timeRequired, setTimeRequired] = useState(0);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    const serviceCategoriesData = get(coupon, 'serviceCategories');
    if (!serviceCategoriesData) { return; }
    setServiceCategories(serviceCategoriesData.slice(0, MENU_LIMIT_DISPLAY));
    setTimeRequired(sumBy(coupon, 'treatmentDuration'))
  }, [coupon])

  const isDiffStore = () => {
    const selectedStore = getStore();
    const selectedStaff = getStylist();
    return (selectedStore && selectedStore.id !== storeId) || (selectedStaff && !selectedStaff.couponIds.includes(coupon.id))
  }

  const onBooking = () => {
    if (isDiffStore()) {
      clearBooking()
    }
    
    const coupons = getCoupon() || []
    const menuCoupons = coupons?.filter((coupon) => coupon?.defineCategory) || []
    const menus = getMenus() || []

    if (!coupon.defineCategory) {
      if (menus.length == 0 && menuCoupons.length === 0) {
        notification.error({
          message: t('coupon.errors.menuNotSelected')
        });
        return;
      }

      const totalPrice = sumBy(menus, 'priceWithTax') + sumBy(menuCoupons, 'price');
      const discount = calculateDiscount([coupon], totalPrice);
      if (discount > totalPrice) {
        notification.error({
          message: t('coupon.errors.couponDiscountGreaterThanPrice')
        });
        return;
      }
    }
    addCoupon(coupon);
    setAddCouponMenuFlag(true)
    
    if (params.ref) {
      window.location = params.ref
    } else {
      window.location = `/reservation_users/stores/${storeId}/bookings/schedule`
    }
  }

  return (
    <Card className="coupon__card my-4" bordered={false}>
      <Row className="px-4 coupon-info" gutter={24} wrap={false}>
        <Col flex="auto">
          <Paragraph className="coupon__name flex-2" style={{margin: 0}} ellipsis={ellipsis ? { rows: 3, expandable: true, symbol: t('common.expandEllips') } : false}>
            <Title level={5}>{get(coupon, 'name')}</Title>
          </Paragraph>
          <Space direction="horizontal" size={0}>
            <Tag color="#E96E75">{t(`coupon.enums.primary_category.${get(coupon, 'primaryCategory')}`)}</Tag>
            {Array.isArray(serviceCategories) && serviceCategories.map(category => {
              return (
                <Tag color="magenta">{t(`coupon.enums.serviceCategories.${category}`)}</Tag>
              )
            })}
          </Space>
        </Col>
        <Col flex="none">
          <Paragraph className="text-right font-bold" style={{margin: 0}}>
            {discountValueFormatter(coupon)}
          </Paragraph>
          { 
            get(coupon, 'treatmentDuration') &&
            (
              <Paragraph className="text-right" style={{margin: 0}}>
                {convertMinsToHour(coupon.treatmentDuration)}
              </Paragraph>
            )
          }
        </Col>
      </Row>
      <Row className="px-4 coupon-info" gutter={18} wrap={false}>
        <Col flex="none">
          <div className="coupon__image flex-none rounded" style={{ backgroundImage: `url("${get(coupon, 'image')}")` }} />
        </Col>
        <Col flex="auto">
          {
            get(coupon, 'description') && 
            (
              <Paragraph>{get(coupon, 'description')}</Paragraph>
            )
          }
          {
            get(coupon, 'termOfUse') && 
            (
              <Paragraph>
                <Paragraph style={{margin: 0}} >{t('coupon.termOfUse')}</Paragraph>
                <Paragraph style={{margin: 0}} className="pl-4"><small>{get(coupon, 'termOfUse')}</small></Paragraph>
              </Paragraph>
            )
          }
          {
            get(coupon, 'secondaryTermOfUse') && 
            (
              <Paragraph>
                <Paragraph style={{margin: 0}} >{t('coupon.secondTermOfUse')}</Paragraph>
                <Paragraph style={{margin: 0}} className="pl-4"><small>{get(coupon, 'secondaryTermOfUse')}</small></Paragraph>
              </Paragraph>
            )
          }
        </Col>
      </Row>
      { showButtons &&
        <Row className="flex flex-row py-4 px-4" gutter={[8, 2]}>
          <Col span={12}/>
          <Col span={12}>
            <Button className="btn-default btn btn-small text-sm" onClick={onBooking}>
              {t('coupon.bookingBtn')}
            </Button>
          </Col>
        </Row>
      }
    </Card>
  );
}
