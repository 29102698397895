import React from 'react'
import { Card, Typography, Row, Col } from 'antd';
import { get } from 'lodash'
import { t } from '../../helpers/translation'
import reservationSvg from 'images/reservation.svg' 
import './styles.scss'

const { Title } = Typography;

export const NewStoreCard = ({ store }) => {
  const onGoStoreDetail = () => {
    window.location.pathname = `/reservation_users/stores/${store.id}`
  }

  return (
    <button className='w-full mb-4' onClick={onGoStoreDetail}>
      <Card className='store__card' bordered={false} hoverable>
        <Row className='p-4' gutter={16} wrap={false}>
          <Col flex="none">
            <img className='store__image flex-none rounded-sm' src={get(store, 'image')} />
          </Col>
          <Col flex="auto">
            <Title level={5} className='text-left'>{get(store, 'name')}</Title>
            <Row className='mt-2' gutter={8} wrap={false}>
              <Col flex="none">
                <img src={reservationSvg} className='store__reservation-image'/>
              </Col>
              <Col flex="auto">
                <Typography className='text-left text-xs w-full'>{get(store, 'nearestStation')}</Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </button>
  );
}
