import { getItem, setItem, removeItem } from './localStorage';
import { sumBy } from 'lodash'
export const STYLIST = 'reservation_STYLIST'
export const COUPON = 'reservation_COUPON'
export const MENU = 'reservation_MENU'
export const ADD_NEW_COUPONMENU_FLAG = 'add_new_COUPONMENU_flag'
export const EDIT_STYLIST_FLAG = 'edit_STYLIST_flag'
export const SCHEDULE = 'reservation_SCHEDULE'
export const USE_POINT = 'USE_POINT'
export const STORE = 'reservation_STORE'
export const MININUM_AMOUNT = 100

import _uniqBy from 'lodash/uniqBy'
import { currency } from '../utils/number';
import { t } from '../helpers/translation';

export const addStylist = (stylist) => {
  setItem(STYLIST, stylist)
}

export const getStylist = () => {
  return getItem(STYLIST)
}

export const clearStylist = () => {
  removeItem(STYLIST)
}

export const addCoupon = (coupon) => {
  setItem(COUPON, [coupon])
}

export const getUsePoint = () => {
  return getItem(USE_POINT)
}

export const setAddCouponMenuFlag = (value) => {
  setItem(ADD_NEW_COUPONMENU_FLAG, value)
}

export const getAddCouponMenuFlag = () => {
  return getItem(ADD_NEW_COUPONMENU_FLAG)
}

export const setEditStylistFlag = (value) => {
  setItem(EDIT_STYLIST_FLAG, value)
}

export const getEditStylistFlag = () => {
  return getItem(EDIT_STYLIST_FLAG)
}

export const setUsePoint = (value) => {
  setItem(USE_POINT, value)
}

export const removeCoupon = (coupon) => {
  const coupons = getItem(COUPON) || []
  setItem(COUPON, coupons.filter((c) => c.id != coupon.id))
}

export const getCoupon = () => {
  return getItem(COUPON)
}

export const addMenu = (menus) => {
  const data = _uniqBy(menus, function (menu) {
    return menu.id;
  });
  setItem(MENU, data)
}

export const removeMenu = (menu) => {
  const menus = getItem(MENU) || []
  setItem(MENU, menus.filter((m) => m.id != menu.id))
}


export const getMenus = () => {
  return getItem(MENU)
}

export const addSchedule = (schedule) => {
  setItem(SCHEDULE, schedule)
}

export const addStore = (store) => {
  setItem(STORE, store)
}

export const getStore = () => {
  return getItem(STORE)
}

export const getSchedule = () => {
  return getItem(SCHEDULE)
}

export const discountCalculate = (coupon, totalAmount) => {
  if (!coupon || !totalAmount) { return 0 };

  const couponRule = coupon.couponRule
  if (couponRule?.valueType === 'fixed_amount') {
    return couponRule.value;
  }

  if (couponRule?.valueType === 'percentage') {
    return totalAmount * couponRule.value / 100 ;
  }

  if (couponRule?.valueType === 'free') {
    return totalAmount;
  }
}

export const pointEarnedCalculate = (coupons, menus) => {
  return (sumBy(menus, 'pointEarned') || sumBy(menus, 'point_earned')) + (sumBy(coupons, 'pointEarned') || sumBy(coupons, 'point_earned'))
}

export const discountValueFormatter = (coupon, showTax = true) => {
  const tax = showTax ? t("common.taxInclude") : '';
  const couponRule = coupon?.couponRule

  if (coupon.old) {
    if (coupon?.discounted && coupon?.discountValue > 0) {
      return currency(coupon?.discountValue);
    }
    if (coupon?.couponPrice && coupon?.couponPrice > 0) {
      return `${currency(coupon.couponPrice)} ${tax}`;
    }
  }

  if (coupon?.defineCategory) {
    return `${currency(coupon.price)} ${tax}`;
  }

  if (couponRule?.valueType === 'fixed_amount') {
    return currency(couponRule?.value);
  }

  if (couponRule?.valueType === 'percentage') {
    return `${couponRule?.value}%`;
  }

  if (couponRule?.valueType === 'free') {
    return '100%';
  }
}

export const discountValue = (coupon) => {
  if (!coupon) {
    return 0
  }

  if (coupon?.defineCategory) {
    return coupon.price;
  }

  const couponRule = coupon?.couponRule

  if (couponRule?.valueType === 'fixed_amount') {
    return couponRule?.value;
  }

  if (couponRule?.valueType === 'percentage') {
    return couponRule?.value;
  }

  if (couponRule?.valueType === 'free') {
    return 100;
  }
}

export const calculateDiscount = (coupons, totalMenuPrice) => {
  const discountCoupon = coupons?.map((coupon) => {
    if (coupon.old && coupon.discountValue && coupon.discountValue > 0) {
      return coupon.discountValue;
    }

    const couponRule = coupon?.couponRule
    if (couponRule?.valueType === 'fixed_amount') {
      if (couponRule?.value > totalMenuPrice) {
        return totalMenuPrice;
      }

      return couponRule?.value;
    }
  
    if (couponRule?.valueType === 'percentage') {
      return totalMenuPrice * couponRule.value / 100;
    }
  
    if (couponRule?.valueType === 'free') {
      return totalMenuPrice;
    }
  }).reduce((left, right) => left + right, 0);

  return discountCoupon
}

export const clearBooking = () => {
  removeItem(STYLIST);
  removeItem(COUPON);
  removeItem(MENU);
  removeItem(SCHEDULE);
  removeItem(STORE);
  removeItem(ADD_NEW_COUPONMENU_FLAG);
  removeItem(EDIT_STYLIST_FLAG);
}

export const clearAddNewCouponMenuFlag = () => {
  removeItem(ADD_NEW_COUPONMENU_FLAG);
}

export const clearCoupon = () => {
  removeItem(COUPON);
}

export const clearMenu = () => {
  removeItem(MENU);
}

export const clearSchedule = () => {
  removeItem(SCHEDULE);
}

export const clearStore = () => {
  removeItem(STORE);
}

export const pointCalculate = (usePoint, totalPriceExceptPoints, reedemedPoints) => {
  if (!usePoint) { return 0 }
  return Math.floor(
    (reedemedPoints >= totalPriceExceptPoints ? (totalPriceExceptPoints - MININUM_AMOUNT <= MININUM_AMOUNT ? totalPriceExceptPoints : totalPriceExceptPoints - MININUM_AMOUNT) : reedemedPoints) / 100
  ) * 100
}

export const isCouponValid = (coupon, date) => {
  if (
    (coupon.startedDate !== null &&
      coupon.expiredDate === null &&
      new Date(coupon.startedDate) <= date) ||
    (coupon.startedDate !== null &&
      coupon.expiredDate !== null &&
      new Date(coupon.startedDate) <= date &&
      new Date(coupon.expiredDate) >= date) ||
    (coupon.startedDate === null &&
      coupon.expiredDate !== null &&
      new Date(coupon.expiredDate) >= date) ||
    (coupon.startedDate === null && coupon.expiredDate === null)
  ) {
    return true;
  }
  return false;
}

// TODO move to string utils because it's not a booking service
export const objectToQueryString = (params) => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          searchParams.append(key, item);
        });
      } else {
        searchParams.set(key, value);
      }
    }
  }

  return searchParams.toString();
};
