import React, { useState, useEffect } from "react"
import { Tabs, Button, Empty } from 'antd';
import { CouponCard } from '../../CouponCard';
import RecommendProducts from '../RecommendProducts';
import './styles.scss'
import { t } from '../../../helpers/translation'
import { getMenus } from "../../../helpers/bookings";
import BookingMenuList from "../../../ec_users_containers/BookingMenu/BookingMenuList";

const { TabPane } = Tabs;

const StaffTab = ({ staff, storeId, menus, coupons, products, activeTab, setActiveTab }) => {
  const [tabActive, setTabActive] = useState(activeTab)
  const [selectedMenus, setSelectedMenus] = useState([])

  useEffect(() => {
    setSelectedMenus(getMenus() || []);
  }, [])

  const onChange = (key) => {
    setTabActive(`${key}`);
    setActiveTab(key)
  }

  const handleClick = () => {
    window.location.href = '/'
  }

  return (
    <>
      <div id="tab-info" />
      <Tabs onChange={onChange} activeKey={tabActive} centered className="tab-info">
        <TabPane tab={t('staff.menus')} key="coupon_info">
          {coupons.length <= 0 &&
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('common.no_data')}/>
          }
          <div className="coupon-list-wrapper">
            {coupons.map((coupon, idx) => {
              return (
                <CouponCard coupon={coupon} key={idx} storeId={staff.storeId}/>
              )
            })}
          </div>
          <div>
            <BookingMenuList storeId={storeId} menus={menus} selectedMenus={selectedMenus} />
          </div>
        </TabPane>
        <TabPane tab={t('staff.display_name')} key="ec">
          <RecommendProducts products={products} stylist={staff} />
          <div className="py-10 px-20 bg-gray-50 w-100">
            <Button className="btn-outline-primary btn btn-small" onClick={handleClick}>
              {t('staff.goBackEc')}
            </Button>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}

export default StaffTab
