import React, { useState, useEffect } from 'react'
import { t } from '../../helpers/translation';
import { get } from 'lodash';
import {convertMinsToHour, currency} from "../../utils/number";
import {Button, Row, Col, Typography, Tag} from "antd";
import './styles.scss'

const { Title, Paragraph } = Typography;

const MenuCard = ({ menu, onAddMenu, onDeleteMenu }) => {
  if (!menu) {
    return (<></>)
  }
  return (
    <div key={menu.id} className='menus-container'>
      <Row gutter={24} wrap={false}>
        <Col flex="auto">    
          <div className="w-24 text-xs w-full self-center">
            <Title level={5} className="font-bold">{menu.name}</Title>
          </div>
          <div className='header-section header-section flex flex-wrap gap-2 text-sm'>
            {menu.serviceCategories && menu.serviceCategories.slice(0, 8).map((serviceCategory) => (
              <Tag color='magenta'>{ serviceCategory }</Tag>
            ))}
          </div>
        </Col>
        <Col flex="none">    
          <div className='flex'>
            <div className="text-right flex-none">
              <Paragraph className="font-bold" style={{margin: 0}}>{`${currency(menu.priceWithTax, menu.isShowMark)} ${t('common.taxInclude')}`}</Paragraph>
              <Paragraph style={{margin: 0}}>{convertMinsToHour(menu.requiredTime)}</Paragraph>
            </div>
          </div>
        </Col>
      </Row>
      <Row className='pt-4'>
        <Col>
          {
            get(menu, 'description') && 
            (
              <Paragraph>{get(menu, 'description')}</Paragraph>
            )
          }
          </Col>
      </Row>
      <div className='flex content-right'>
        {
          onDeleteMenu && (
            <Button className="float-right-btn btn-default btn btn-small my-4 text-sm" onClick={onDeleteMenu}>
              {t('booking.delete')}
            </Button>
          )
        }
        {
          onAddMenu && (
            <Button className="float-right-btn btn-default btn btn-small my-4 text-sm" onClick={onAddMenu}>
              { t('common.addMenuItemBtn') }
            </Button>
          )
        }
      </div>
    </div>
  )
}

export default MenuCard
