import React from "react"
import { Card, Typography, Button, Row, notification } from 'antd';
import { get } from 'lodash'
import { t } from '../../../helpers/translation'
import { objectToQueryString } from '../../../helpers/bookings'
import { currency } from '../../../utils/number'
import './styles.scss'

const { Paragraph } = Typography;

export const StylistCard = ({ stylist, store, reservationId, coupons, menus }) => {
  const [ellipsis, setEllipsis] = React.useState(true);

  const chooseStaff = (chooseAnother = false) => {
    let ref = null;
    if (chooseAnother && store.staffCapacity <= 1) {
      notification.error({message: t("staff.noOtherStaffError")})
      return
    }

    if (reservationId) {
      ref = `/reservation_users/stores/${store.id}/bookings/${reservationId}/change`
      window.location.href = `/reservation_users/stores/${store.id}?active_tab=2&${ref ? `ref=${ref}` : '' }`
      return
    }

    const couponIds = coupons ? _.map(coupons, (cp) => (cp.id)) : []
    const menuIds = menus ? _.map(menus, (mn) => (mn.id)) : []
    const queryString = objectToQueryString({ 'coupon_ids[]': couponIds, 'menu_ids[]': menuIds, filter_data: true })
    window.location.href = `/reservation_users/stores/${store.id}?active_tab=2&from_booking_schedule=true&${queryString}`

  }

  const EmptyStylist = () => {
    return (
      <div className="my-8 px-4">
        <Row justify="center">
          <div className="mb-4 text-xs text-gray-777">
            {t('booking.noItem')}
          </div>
        </Row>
        <Row justify="center">
          <Button className="btn btn-outline" onClick={() => chooseStaff()}>
            {t('booking.selectStylistTitle')}
          </Button>
        </Row>
      </div>
    )
  }
  
  const Stylist = () => {
    return (
      <div className="px-4 my-4 mt-4 flex">
        <Card className="stylist-booking__card" bordered={false} hoverable>
          <div className="flex flex-row h-full">
            <div className="stylist-booking__avatar flex-none" style={{ backgroundImage: `url("${get(stylist, 'avatar')}")` }} />
            <div className="py-2 px-4 w-full">
              <Typography className="text-xs">{t('staff.selectedTitle')}</Typography>
              <div className="flex flex-row items-center my-1">
                <Typography className="stylist-booking__name mr-2">{get(stylist, 'displayName')}</Typography>
              </div>
              { get(stylist, 'nominated?') &&
                <div className="flex flex-row items-center">
                  <div className="mr-2 bg-gray-100 px-2 font-bold">{t('staff.nominationFee')}</div>
                  <Typography className="font-bold">
                    {currency(get(stylist, 'nominationFeeValue'))}
                    <span>
                      { t('common.taxInclude') }
                    </span>
                  </Typography>
                </div>
              }
              <a
                className="font-bold text-primary text-xs mt-2"
                onClick={() => chooseStaff() }
              >
                {t('staff.seeAnotherScheduleStaff')}
              </a>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  return (
    <>
      {!!stylist
        ? <Stylist />
        : <EmptyStylist />
      }
    </>
  );
}
