import React, { useState, useEffect } from 'react'
import { notification } from 'antd';
import {getMenus, addMenu, clearBooking, getStore, getStylist, getCoupon, setAddCouponMenuFlag } from '../../helpers/bookings'
import {MAX_MENU_QUANTITY} from "../../utils/constant";
import {omit} from "lodash";
import { t } from '../../helpers/translation'
import './styles.scss'
import MenuCard from "./MenuCard";

const BookingMenuList = ({ storeId, menus, selectedMenus, onMenuSelected }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const isDifferentStore = (menuId) => {
    const selectedStaff = getStylist();
    const selectedStore = getStore();
    return (selectedStore && selectedStore.id !== storeId) || (selectedStaff && !selectedStaff.menuIds.includes(menuId))
  }

  const addMenuBooking = (item) => {
    if (isDifferentStore(item.id)) {
      clearBooking()
    }
    const coupons = getCoupon() || []
    const menus = getMenus() || []

    if (coupons.length > 0 && menus.length >= MAX_MENU_QUANTITY) {
      notification.error({
        message: t('booking.invalidMenuQuantity', { maxMenuQuantity: MAX_MENU_QUANTITY })
      });
      return;
    }
    addMenu((getMenus() || []).concat([item]))
    setAddCouponMenuFlag(true)
    
    if (onMenuSelected) {
      onMenuSelected(selectedMenus.concat([item]))
    }
    if (params.ref) {
      window.location = params.ref
    } else {
      window.location = `/reservation_users/stores/${storeId}/bookings/schedule`
    }
  }

  return (
    <div style={{margin: "20px"}}>
      {
        menus.map((item, idx) => {
          return (
            <div key={idx}>
              {
                <MenuCard key={item.id} menu={item} category={item.categoryStr} onAddMenu={() => addMenuBooking(item)} />
              }
            </div>
          );
        })
      }
    </div>
  );
}

export default BookingMenuList
